import {
    Box,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    Radio,
    RadioGroup,
    Text,
    useCheckboxGroup,
    VStack,
    Input,
} from '@chakra-ui/react'
import { useSetAttributesMutation } from '@core/app/api/profileApi'
import { AppButton } from '@core/components/AppButton'
import { AutoResizeTextarea } from '@core/components/ResizeTextarea/ResizeTextarea'
import { SelectZTop } from '@core/components/SelectZTop/Select'
import { DefaultSelect } from '@core/components/Select/Select'
import { StarRating } from '@core/components/StarRating/StarRating'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import { getDaysArray } from '@core/utils/helpers/getDaysArray'
import { getYearsArray } from '@core/utils/helpers/getYearsArray'

type DayjsType = ReturnType<typeof dayjs>

interface Props {
    AvailableValues: {
        Dropdowns: {
            [key: string]: {
                DropdownID: number
                Name: string
            }
        }
        Checkboxes: {
            [key: string]: {
                CheckboxID: number
                Name: string
            }
        }
    }
    CandidateAttributeID: string
    IsBool: string
    IsCheckbox: string
    IsCheckboxRating: string
    IsDropdown: string
    IsRating: string
    isText: string
    isTextInput: string
    isDate: string
    Name: string
    bg?: string
    jobId?: string
}

interface FormValues {
    text: string
    textInput: string
    dropdown: {
        label: string
        value: string
    }
    rate: number
    radioValue: number | null
    date: {
        day: { label: string; value: string }
        month: { label: string; value: string }
        year: { label: string; value: string }
    }
}

export const AddAttribute = ({
    Name,
    IsBool,
    isDate,
    IsRating,
    IsDropdown,
    IsCheckbox,
    IsCheckboxRating,
    AvailableValues,
    isText,
    isTextInput,
    CandidateAttributeID,
    bg,
    jobId,
}: Props) => {
    const [setAttribute, { isLoading }] = useSetAttributesMutation()
    const {
        value: checkboxValue,
        getCheckboxProps,
        setValue,
    } = useCheckboxGroup({
        defaultValue: [],
    })
    const [checkboxRating, setCheckboxRating] = useState<{ id: number; rating: number }[]>([])
    const { t } = useTranslation()

    const days = getDaysArray()
    const years = getYearsArray('education')
    const Months = [
        { value: '01', label: `${t('477')}` },
        { value: '02', label: `${t('478')}` },
        { value: '03', label: `${t('479')}` },
        { value: '04', label: `${t('480')}` },
        { value: '05', label: `${t('481')}` },
        { value: '06', label: `${t('482')}` },
        { value: '07', label: `${t('483')}` },
        { value: '08', label: `${t('484')}` },
        { value: '09', label: `${t('485')}` },
        { value: '10', label: `${t('486')}` },
        { value: '11', label: `${t('487')}` },
        { value: '12', label: `${t('488')}` },
    ]

    const { appTheme } = useAppTheme()
    const [boolError, setBoolError] = useState<string>('')
    const [dateError, setDateError] = useState<string>('')
    const [ratingError, setRatingError] = useState<string>('')
    const [dropdownError, setDropdownError] = useState<string>('')
    const [checkboxError, setCheckboxError] = useState<string>('')
    const [textError, setTextError] = useState<string>('')
    const [textInputError, setTextInputError] = useState<string>('')

    const currentDate = dayjs()
    const initialValues: FormValues = {
        radioValue: null,
        rate: 0,
        dropdown: {
            label: '',
            value: '',
        },
        text: '',
        textInput: '',
        date: {
            // day: { label: currentDate.format('DD'), value: currentDate.format('DD') },
            // month: { label: currentDate.format('MM'), value: currentDate.format('MM') },
            // year: { label: currentDate.format('YYYY'), value: currentDate.format('YYYY') },
            day: { label: '', value: '' },
            month: { label: '', value: '' },
            year: { label: '', value: '' },
        },
    }

    const setAttributeHandler = async (values: FormValues, reset: Function) => {
        const checkboxes: number[] = [
            ...checkboxValue.map((value: string | number) => {
                return +value
            }),
        ]

        let checkboxObject = {}

        checkboxes.map((value: number) => {
            checkboxObject = {
                ...checkboxObject,
                [value.toString()]: {
                    checkboxId: value,
                    rating: checkboxRating.find((item) => item.id === value)?.rating,
                },
            }
        })

        try {
            await setAttribute({
                data: {
                    id: +CandidateAttributeID,
                    jobId: jobId ? +jobId : undefined,
                    bool: values.radioValue !== null ? values.radioValue === 1 : undefined,
                    text: values.text,
                    textInput: values.textInput,
                    rating: values.rate,
                    dropdown: +values.dropdown?.value !== 0 ? +values.dropdown.value : undefined,
                    checkboxes: Object.values(checkboxObject || {}).length > 0 ? checkboxObject : undefined,
                    date: values.date
                        ? `${values.date?.year?.value}-${values.date?.month?.value}-${values.date?.day?.value}`
                        : undefined,
                },
            }).unwrap()
            reset()
        } catch (error: any) {
            if (error?.data?.data?.errors) {
                const { date, bool, rating } = error.data?.data?.errors
                if (date) {
                    setDateError(date[0])
                }
                if (bool) {
                    setBoolError(bool[0])
                }
                if (rating) {
                    setRatingError(rating[0])
                }
            } else {
                console.error(error)
                console.log('An error occurred:', error)
                console.log('Error message:', error?.data?.message || 'An unknown error occurred')
            }
        } finally {
            // reset()
        }
    }

    const getMemoizedStarRating = (checkboxID: number) => {
        const checkboxRatingForValue = checkboxRating.find((item) => item.id === checkboxID)
        const ratingForValue = checkboxRatingForValue?.rating || 0

        return (
            <StarRating
                defaultState={ratingForValue}
                height={18}
                width={18}
                labelText={() => ''}
                onChangeValue={(rating: number) => {
                    setCheckboxRating((prevState) => [
                        ...prevState.filter((item) => item.id !== checkboxID),
                        { id: checkboxID, rating: rating },
                    ])

                    if (!checkboxValue.includes(checkboxID) && ratingForValue !== 0) {
                        setValue([...checkboxValue, checkboxID])
                    }
                }}
                showLabel={false}
                maxValue={5}
                readOnly={false}
            />
        )
    }

    const getCheckboxes = (id: number, name: string) => {
        return (
            <Flex w={'full'} justifyContent={'space-between'} alignItems={'center'}>
                <Checkbox
                    w={'80%'}
                    key={id}
                    value={id}
                    isChecked={checkboxValue.includes(id)}
                    onChange={() => {
                        if (!checkboxValue.includes(id)) {
                            setValue([...checkboxValue, id])
                        } else {
                            setValue([...checkboxValue.filter((value) => value !== id)])
                        }

                        setCheckboxRating((prevState) => {
                            const updatedRating = prevState.map((item) => {
                                if (item.id === id) {
                                    return {
                                        ...item,
                                        rating: 0,
                                    }
                                }
                                return item
                            })
                            return updatedRating
                        })
                    }}
                >
                    <Text fontSize={'sm'} fontWeight={'medium'} color={'text.secondary'} w={'full'} pr={2}>
                        {name}
                    </Text>
                </Checkbox>
                {IsCheckboxRating ? getMemoizedStarRating(id) : null}
            </Flex>
        )
    }

    return (
        <Box px={6} py={5} border={'1px solid'} borderColor={'state.primary'} borderRadius={'4px'} bg={bg}>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    dropdown: Yup.object().shape({
                        value: Yup.string().when([], {
                            is: () => +IsDropdown === 1,
                            then: Yup.string().required(t('8738') as string), // Please select a value
                            otherwise: Yup.string().notRequired(),
                        }),
                        label: Yup.string().when([], {
                            is: () => +IsDropdown === 1,
                            then: Yup.string().required(t('8738') as string), // Please select a value
                            otherwise: Yup.string().notRequired(),
                        }),
                    }),
                    // text: Yup.string().when([], {
                    //     is: () => +isText === 1,
                    //     then: Yup.string().required('Please fill out this field').max(2000, 'Max 2000 characters'),
                    //     otherwise: Yup.string().notRequired(),
                    // }),
                    // textInput: Yup.string().when([], {
                    //     is: () => +isTextInput === 1,
                    //     then: Yup.string().required('Please fill out this field'),
                    //     otherwise: Yup.string().notRequired(),
                    // }),
                    radioValue: Yup.number()
                        .nullable()
                        .when([], {
                            is: () => +IsBool === 1,
                            then: Yup.number()
                                .nullable(true)
                                .required(t('8739') as string) // Please select Yes or No
                                .oneOf([0, 1], t('8739') as string), // Please select Yes or No
                            otherwise: Yup.number().nullable(true).notRequired(),
                        }),
                    rate: Yup.number().when([], {
                        is: () => +IsRating === 1,
                        then: Yup.number()
                            .required(t('8740') as string) // Please select a rating
                            .min(1, t('8740') as string), // Please select a rating
                        otherwise: Yup.number().notRequired(),
                    }),
                    // date: Yup.object().shape({
                    //     day: Yup.object().shape({
                    //         value: Yup.string().when([], {
                    //             is: () => +isDate === 1,
                    //             then: Yup.string().required('Please enter a valid date'),
                    //             otherwise: Yup.string().notRequired(),
                    //         }),
                    //         label: Yup.string().when([], {
                    //             is: () => +isDate === 1,
                    //             then: Yup.string().required('Please enter a valid date'),
                    //             otherwise: Yup.string().notRequired(),
                    //         }),
                    //     }),
                    //     month: Yup.object().shape({
                    //         value: Yup.string().when([], {
                    //             is: () => +isDate === 1,
                    //             then: Yup.string().required('Please enter a valid date'),
                    //             otherwise: Yup.string().notRequired(),
                    //         }),
                    //         label: Yup.string().when([], {
                    //             is: () => +isDate === 1,
                    //             then: Yup.string().required('Please enter a valid date'),
                    //             otherwise: Yup.string().notRequired(),
                    //         }),
                    //     }),
                    //     year: Yup.object().shape({
                    //         value: Yup.string().when([], {
                    //             is: () => +isDate === 1,
                    //             then: Yup.string().required('Please enter a valid date'),
                    //             otherwise: Yup.string().notRequired(),
                    //         }),
                    //         label: Yup.string().when([], {
                    //             is: () => +isDate === 1,
                    //             then: Yup.string().required('Please enter a valid date'),
                    //             otherwise: Yup.string().notRequired(),
                    //         }),
                    //     }),
                    // }),
                })}
                onSubmit={(values, { setSubmitting, resetForm, validateForm, validateField }) => {
                    if (+isDate === 1) {
                        if (!values.date?.day?.value || !values.date?.month?.value || !values.date?.year?.value) {
                            setDateError(t('8741') as string) // Please enter a valid date
                            return
                        }
                    }
                    const submitted = () => {
                        setSubmitting(false)
                        // resetForm()
                    }

                    setAttributeHandler(values, submitted)
                }}
            >
                {({ setFieldValue, handleSubmit, values, handleChange, errors, touched }) => {
                    // const isDateSelected = !!values.date.day.value && !!values.date.month.value && !!values.date.year.value
                    // const isCheckboxSelected = checkboxValue.length > 0
                    return (
                        <Form onSubmit={handleSubmit} style={{ marginBottom: '0' }}>
                            <Flex w={'full'} flexDirection={'column'} gap={5}>
                                <Text fontSize={'xl'} fontWeight={'semibold'} color={'text.main'}>
                                    {Name}
                                </Text>
                                {+IsBool === 1 ? (
                                    <FormControl>
                                        <RadioGroup
                                            // @ts-ignore
                                            value={values.radioValue + ''}
                                            onChange={(value) => {
                                                setFieldValue('radioValue', +value)
                                                setBoolError('')
                                            }}
                                        >
                                            <VStack alignItems={'start'}>
                                                <Radio value={'1'}>
                                                    <Text
                                                        fontSize={'sm'}
                                                        fontWeight={'semibold'}
                                                        color={values.radioValue === 1 ? 'state.primary' : '#494949'}
                                                    >
                                                        {t('128')}
                                                    </Text>
                                                </Radio>
                                                <Radio value={'0'}>
                                                    <Text
                                                        fontSize={'sm'}
                                                        fontWeight={'semibold'}
                                                        color={values.radioValue === 0 ? 'state.primary' : '#494949'}
                                                    >
                                                        {t('129')}
                                                    </Text>
                                                </Radio>
                                            </VStack>
                                        </RadioGroup>
                                        <Text color={'text.error'} fontSize={'xs'} mt={0.5}>
                                            {boolError}
                                            {errors.radioValue && touched.radioValue && errors.radioValue}
                                        </Text>
                                    </FormControl>
                                ) : null}
                                {+IsRating === 1 ? (
                                    <FormControl>
                                        <StarRating
                                            defaultState={0}
                                            height={22}
                                            labelText={() => `${t('7410')}`}
                                            maxValue={5}
                                            onChangeValue={(value: number) => {
                                                setFieldValue('rate', value)
                                                setRatingError('')
                                            }}
                                            readOnly={false}
                                            width={22}
                                        />

                                        <Text color={'text.error'} fontSize={'xs'} mt={0.5}>
                                            {ratingError}
                                            {errors.rate && touched.rate && errors.rate}
                                        </Text>
                                    </FormControl>
                                ) : null}
                                {+IsDropdown === 1 && Object.values(AvailableValues?.Dropdowns || {}).length > 0 ? (
                                    <FormControl isInvalid={!!errors.dropdown?.label && touched.dropdown?.label}>
                                        <Flex w={'full'} flexDirection={'column'} gap={2}>
                                            <Text fontSize={'sm'} fontWeight={'medium'} color={'text.main'}>
                                                {t('7411')}
                                            </Text>
                                            <DefaultSelect
                                                placeholder={`${t('7411')}`}
                                                options={[
                                                    ...Object.values(AvailableValues?.Dropdowns || {}).map(
                                                        (value: { DropdownID: number; Name: string }) => ({
                                                            label: value.Name,
                                                            value: value.DropdownID.toString(),
                                                        })
                                                    ),
                                                ]}
                                                height="100px"
                                                onChange={(value) => {
                                                    setFieldValue('dropdown', value)
                                                    setDropdownError('')
                                                }}
                                                values={values.dropdown?.label}
                                                searchable={false}
                                            />
                                        </Flex>
                                        <Text color={'text.error'} fontSize={'xs'} mt={0.5}>
                                            {dropdownError}
                                        </Text>
                                        <FormErrorMessage>
                                            {errors.dropdown?.label &&
                                                touched.dropdown?.label &&
                                                errors.dropdown?.label}
                                        </FormErrorMessage>
                                    </FormControl>
                                ) : null}
                                {+IsCheckbox === 1 &&
                                AvailableValues.Checkboxes &&
                                Object.values(AvailableValues.Checkboxes || {}).length > 0 ? (
                                    <FormControl>
                                        <Flex w={'full'} flexDirection={'column'} gap={2}>
                                            <Text fontSize={'sm'} fontWeight={'medium'} color={'text.main'}>
                                                {t('7412')}
                                            </Text>
                                            <Flex w={'full'} flexDirection={'column'} gap={2}>
                                                {Object.values(AvailableValues.Checkboxes || {}).map(
                                                    (value: { CheckboxID: number; Name: string }) =>
                                                        getCheckboxes(value.CheckboxID, value.Name)
                                                )}
                                            </Flex>
                                        </Flex>
                                    </FormControl>
                                ) : null}
                                {+isText === 1 ? (
                                    <FormControl>
                                        <Flex w={'full'} flexDirection={'column'} gap={2}>
                                            <Text fontSize={'sm'} fontWeight={'medium'} color={'text.main'}>
                                                {t('73')}
                                            </Text>
                                            <AutoResizeTextarea
                                                name={'text'}
                                                maxLength={2000}
                                                value={values.text}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    setTextError('')
                                                }}
                                            />
                                            <Text color={'text.error'} fontSize={'xs'} mt={0.5}>
                                                {textError}
                                                {errors.text && touched.text && errors.text}
                                            </Text>
                                        </Flex>
                                    </FormControl>
                                ) : null}
                                {+isTextInput === 1 ? (
                                    <FormControl>
                                        <Flex w={'full'} flexDirection={'column'} gap={2}>
                                            <Input
                                                name={'textInput'}
                                                type={'text'}
                                                value={values.textInput}
                                                onChange={handleChange}
                                                maxLength={100}
                                            />
                                        </Flex>
                                        <Text color={'text.error'} fontSize={'xs'} mt={0.5}>
                                            {textInputError}
                                            {errors.textInput && touched.textInput && errors.textInput}
                                        </Text>
                                    </FormControl>
                                ) : null}
                                {+isDate === 1 ? (
                                    <FormControl>
                                        <Flex w={'full'} gap={2}>
                                            <Flex
                                                w={{
                                                    base: 'full',
                                                    // xl: 'calc(55%)',
                                                    xl: '384px',
                                                }}
                                                flexDirection={{ base: 'column', xl: 'row' }}
                                                gap={1}
                                            >
                                                <FormControl
                                                    w={{ base: '50%', xl: '26%' }}
                                                    isInvalid={!!errors.date?.day?.value && touched.date?.day?.value}
                                                >
                                                    <SelectZTop
                                                        options={days}
                                                        placeholder={`${t('753')}`}
                                                        className={'w-full'}
                                                        values={values.date?.day?.value}
                                                        onChange={(value) => {
                                                            setFieldValue('date.day', value)
                                                            setDateError('')
                                                        }}
                                                        searchable={false}
                                                        menuPlacement="top"
                                                    />
                                                </FormControl>
                                                <FormControl w={{ base: '50%', xl: '41%' }}>
                                                    <SelectZTop
                                                        options={Months}
                                                        placeholder={`${t('931')}`}
                                                        className={'w-full'}
                                                        values={values.date?.month?.value}
                                                        onChange={(value) => {
                                                            setFieldValue('date.month', value)
                                                            setDateError('')
                                                        }}
                                                        searchable={false}
                                                        menuPlacement="top"
                                                    />
                                                </FormControl>
                                                <FormControl w={{ base: '50%', xl: '32%' }}>
                                                    <SelectZTop
                                                        options={years}
                                                        placeholder={`${t('1322')}`}
                                                        className={'w-full'}
                                                        values={values.date?.year.value}
                                                        onChange={(value) => {
                                                            setFieldValue('date.year', value)
                                                            setDateError('')
                                                        }}
                                                        searchable={false}
                                                        menuPlacement="top"
                                                    />
                                                </FormControl>
                                            </Flex>
                                        </Flex>
                                        <Text color={'text.error'} fontSize={'xs'} mt={0.5}>
                                            {dateError}
                                            {(errors.date?.day?.value ||
                                                errors.date?.month?.value ||
                                                errors.date?.year?.value) &&
                                                (touched.date?.year?.value ||
                                                    touched.date?.month?.value ||
                                                    touched.date?.day?.value) &&
                                                errors.date?.day?.value}
                                        </Text>
                                    </FormControl>
                                ) : null}
                                <AppButton
                                    translate="no"
                                    className={'AppButton'}
                                    title={t('12')}
                                    type={'submit'}
                                    variant={'primary'}
                                    w={'120px'}
                                    isLoading={isLoading}
                                    onClick={() => {
                                        if (+isDate === 1) {
                                            if (
                                                !values.date?.day?.value ||
                                                !values.date?.month?.value ||
                                                !values.date?.year?.value
                                            ) {
                                                setDateError(t('8741') as string) // Please enter a valid date
                                            }
                                        }
                                    }}
                                    // isDisabled={!isDateSelected || !isCheckboxSelected}
                                />
                            </Flex>
                        </Form>
                    )
                }}
            </Formik>
        </Box>
    )
}
