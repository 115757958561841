import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import enGb from '@core/utils/translations/en-GB.json'
import translationsVersion from '@core/utils/translationsVersion.json'

console.log('translationsVersion', translationsVersion)

if (process.env.ENV === 'local') {
    const resources = {
        'en-GB': {
            translation: enGb,
        },
    }
    i18next.use(initReactI18next).init({
        resources,
        lng: 'en-GB',
    })
} else {
    i18next.use(Backend).init({
        load: 'currentOnly',
        lng: document.documentElement.lang,
        fallbackLng: false,
        backend: {
            // loadPath: `/translations/{{lng}}.json?v=1.0.88`,
            // update the version in translationsVersion.json or with bash script automatically (run_translations.sh)
            loadPath: `/translations/{{lng}}.json?v=${translationsVersion.major}.${translationsVersion.minor}.${translationsVersion.patch}`,
        },
    })
    i18next.use(initReactI18next).init({})
}

export default i18next
