import { Box } from '@chakra-ui/react'
import { useGetPostSettingsQuery } from '@core/app/api/postsApi'
import { useIsApplyDialog } from '@core/utils/contexts/ApplyDialogContext/ApplDialogContext'

import { Attributes } from '@page/post/components/ApplyJob/Attributes/Attributes'
import { Suspense, lazy, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
const NotAuthorized = lazy(() => import('@page/post/components/ApplyJob/NotAuthorized/NotAuthorized'))

export const ApplyJobBlock = (): JSX.Element => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const postId = location.pathname.includes('/job/')
        ? id
        : localStorage.getItem('apply_dialog') && JSON.parse(localStorage.getItem('apply_dialog')!).id
    const {
        data: settings,
        isLoading: isSettingsLoading,
        isFetching: isSettingsFetching,
        isError: isSettingsError,
    } = useGetPostSettingsQuery(
        {
            id: postId,
            // @ts-ignore
            code: localStorage.getItem('service_code') ? `?code=${localStorage.getItem('service_code')}` : '',
            // path: localStorage.getItem('apply_path') ? `${localStorage.getItem('apply_path')}` : '',
            path: '',
        },
        { skip: !postId }
    )
    const { close } = useIsApplyDialog()
    const isApplyOnly = location.href.includes('apply_only')

    useEffect(() => {
        const service_code = searchParams.get('code')
        // const path = searchParams.get('path')

        // if (path) {
        //     localStorage.setItem('apply_path', path)
        // } else {
        //     localStorage.removeItem('apply_path')
        // }

        if (service_code) {
            localStorage.setItem('service_code', service_code)
            navigate(
                {
                    search: isApplyOnly ? '?apply_only' : '',
                },
                { replace: true }
            )
        }
    }, [searchParams])

    useEffect(() => {
        if (settings?.redirectUrl) {
            location.href = settings.redirectUrl
        }

        if (settings?.isApplicationExist || isApplyOnly) {
            close()
        }
    }, [settings])

    useEffect(() => {
        if (isSettingsError) {
            close()
        }
    }, [isSettingsError])

    return (
        <Box w={'full'} bg={'bg.1'} borderRadius={'8px'}>
            {settings?.isUser &&
                (id ||
                    (localStorage.getItem('apply_dialog') && JSON.parse(localStorage.getItem('apply_dialog')!).id)) && (
                    <Attributes
                        id={
                            location.pathname.includes('/job/')
                                ? id
                                : localStorage.getItem('apply_dialog') &&
                                  JSON.parse(localStorage.getItem('apply_dialog')!).id
                        }
                        settings={settings}
                        isSettingsLoading={isSettingsLoading}
                        isSettingsFetching={isSettingsFetching}
                    />
                )}
            {!settings?.isUser && settings?.redirectUrl === undefined && (
                <Suspense fallback={<div>Loading...</div>}>
                    <NotAuthorized
                        settings={settings}
                        isSettingsLoading={isSettingsLoading}
                        isSettingsFetching={isSettingsFetching}
                    />
                </Suspense>
            )}
        </Box>
    )
}
