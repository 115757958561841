import { Box, Flex, HStack, Image, ModalCloseButton, Text } from '@chakra-ui/react'
import { useChangeLocaleMutation } from '@core/app/api/globalApi'
import { CustomModal } from '@core/components/Modal/Modal'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import checked from '@core/assets/images/checked.svg'
import { Locale, LocaleItem } from '@core/app/types/profileApiType'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'

interface Props {
    locale: Locale
    opened: boolean
    onClose: () => void
}

export const LanguageModal = ({ opened, onClose, locale }: Props) => {
    const [isOpened, setIsOpened] = useState(false)
    const [changeLocale] = useChangeLocaleMutation()

    const { t, i18n } = useTranslation()

    const { appTheme, updateTheme, isExpired, setIsExpired } = useAppTheme()

    const localeList = Object.values(locale?.list || {})

    const changeLocaleHandler = async (id: number) => {
        try {
            await changeLocale({
                data: {
                    languageId: id,
                },
            }).unwrap()
            setIsExpired()

            switch (id) {
                case 2:
                    i18n.changeLanguage('sv-SE')
                    break
                case 4:
                    i18n.changeLanguage('nn')
                    break
                case 26:
                    i18n.changeLanguage('en-GB')
                    break
                case 1:
                    i18n.changeLanguage('en-US')
                    break
                case 3:
                    i18n.changeLanguage('da')
                    break
                case 5:
                    i18n.changeLanguage('pl')
                    break
                case 6:
                    i18n.changeLanguage('de-DE')
                    break
                case 7:
                    i18n.changeLanguage('es-ES')
                    break
                case 8:
                    i18n.changeLanguage('fr-FR')
                    break
                case 9:
                    i18n.changeLanguage('hu')
                    break
                case 10:
                    i18n.changeLanguage('fi')
                    break
                case 11:
                    i18n.changeLanguage('is')
                    break
                case 12:
                    i18n.changeLanguage('et')
                    break
                case 13:
                    i18n.changeLanguage('ru')
                    break
                case 14:
                    i18n.changeLanguage('lt')
                    break
                case 15:
                    i18n.changeLanguage('lv')
                    break
                case 16:
                    i18n.changeLanguage('it-IT')
                    break
                case 17:
                    i18n.changeLanguage('nl-NL')
                    break
                case 18:
                    i18n.changeLanguage('zh-CN')
                    break
                case 19:
                    i18n.changeLanguage('ms-MY')
                    break
                case 20:
                    i18n.changeLanguage('ja')
                    break
                case 21:
                    i18n.changeLanguage('el')
                    break
                case 22:
                    i18n.changeLanguage('sq')
                    break
                case 23:
                    i18n.changeLanguage('uk')
                    break
                case 24:
                    i18n.changeLanguage('pt-PT')
                    break
                case 25:
                    i18n.changeLanguage('pt-BR')
                    break
            }

            location.reload()
        } catch (error) {}
    }

    const isEnglishLang = (value: string) => {
        const str = value
        const parts = str.split('(')
        return parts[0] + ' (' + parts[1]
    }

    useEffect(() => {
        setIsOpened(opened)
    }, [opened])

    return (
        <CustomModal
            toBottom={true}
            isOpen={isOpened}
            isHeaderVisible={false}
            isFooterVisible={false}
            showOverlay={false}
            showFrom={'slideInBottom'}
            onClose={() => onClose()}
            body={
                <Box bg={'bg.1'} borderRadius={'8px 8px 0 0'}>
                    <Flex flexDirection={'column'} gap={6} w={'full'}>
                        <Flex
                            w={'full'}
                            px={4}
                            py={5}
                            borderBottom={'1px solid'}
                            borderColor={'border.1'}
                            position={'relative'}
                        >
                            <Text>{t('6906')}</Text>
                            <ModalCloseButton position={'absolute'} right={4} top={4} />
                        </Flex>
                        <Flex w={'full'} flexDirection={'column'} gap={1} pb={4}>
                            {locale &&
                                localeList.map((lang) => (
                                    <HStack
                                        key={lang.id}
                                        px={4}
                                        py={2}
                                        cursor={'pointer'}
                                        onClick={() => changeLocaleHandler(lang.id)}
                                    >
                                        <Image
                                            src={
                                                process.env.ENV === 'local'
                                                    ? `${process.env.API_URL}/${lang.flag}`
                                                    : lang.flag
                                            }
                                            maxW={5}
                                            alt={lang.name}
                                        />
                                        <Text fontSize={'sm'} fontWeight={'medium'} color={'text.secondary'}>
                                            {lang.name.includes('English(US)')
                                                ? isEnglishLang(lang.name)
                                                : `${lang.name}`}
                                        </Text>

                                        {lang.id === Object.values(locale.current)[0].id ? (
                                            <Box ml={'auto!important'} role="button" aria-label={`${t('620')}`}>
                                                <Image src={checked} alt={lang.name} />
                                            </Box>
                                        ) : null}
                                    </HStack>
                                ))}
                        </Flex>
                    </Flex>
                </Box>
            }
        />
    )
}
