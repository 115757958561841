import {
    Box,
    Container,
    Flex,
    HStack,
    Heading,
    Image,
    Link,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Skeleton,
    SkeletonText,
    Text,
    VStack,
    useMediaQuery,
    useTheme,
} from '@chakra-ui/react'
import { AppButton } from '@core/components/AppButton'

import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useGetJobApplicationsQuery } from '@core/app/api/postsApi'
import { JobItem } from '@core/app/types/globalApiType'
import { IconArrowBackPost } from '@core/assets/icons/arrow_back_post'
import appliedIcon from '@core/assets/images/applied_icon.svg'
import timerIcon from '@core/assets/images/timer_icon.svg'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import { hexColorEffect } from '@core/utils/helpers/hexColorEffect'
import locationIcon from '@page/company/assets/images/location.svg'
import timeIcon from '@page/company/assets/images/time.svg'
import typeIcon from '@page/company/assets/images/type.svg'
import inProgressIcon from '@page/posts/assets/images/progress_loader.svg'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Navigation } from 'swiper/modules'
import { HeaderInfo } from '@core/app/types/profileApiType'

type Content = {
    [key: string | number]: string
}

export const Jobs: FC<{ id: number; headerInfo?: HeaderInfo | undefined; languageId?: number }> = ({
    id,
    headerInfo,
    languageId,
}) => {
    const { data: applications } = useGetJobApplicationsQuery({})
    const { appTheme } = useAppTheme()
    const theme = useTheme()
    // const [langId, setLangId] = useState<number>()
    const [loaded, setLoaded] = useState<boolean>(false)
    const [content, setContent] = useState<Content>({})
    const [jobs, setJobs] = useState<JobItem[]>([])
    const [isDesktop] = useMediaQuery('(min-width: 1230px)', {
        fallback: false,
    })
    const [scheme, setScheme] = useState<Content>({})
    const { t } = useTranslation()
    const [searchParams] = useSearchParams()
    const token = searchParams.get('preview_token') || undefined
    const [buttonUrl, setButtonUrl] = useState<string>('/jobs')

    const addJob = (id: number) => {
        if (id === 0) return
        if (jobs.some((job) => job.AdID === id)) return

        const job = Object.values(appTheme?.newSettings?.landing?.structure?.jobs || {}).find(
            (job: JobItem) => job.AdID === id
        )

        if (job) {
            setJobs((prev) => [...prev, job])
        }
    }

    const getDifferenceIn = (expireDate: string) => {
        const date1 = new Date(expireDate)
        let date2 = new Date()
        // @ts-ignore
        date2 = date2.setHours(0, 0, 0, 0)
        // @ts-ignore
        const diffInMs = Math.abs(date2 - date1)

        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24))

        return diffInDays
    }

    useEffect(() => {
        setContent({})
        const schemeObject: Content = {}
        const landing = appTheme?.newSettings?.landing?.structure?.sections || {}
        const section = landing
            ? Object.values(appTheme?.newSettings?.landing?.structure?.sections || {}).find(
                  (section) => section.name === 'Jobs' && section.id === id
              )
            : null

        const fields = section ? section.fields : null
        schemeObject['primaryColor'] = appTheme?.newSettings?.landing?.colors?.LandingPrimaryBackground
            ? `#${appTheme?.newSettings?.landing?.colors?.LandingPrimaryBackground}`
            : '#565656'
        schemeObject['secondaryColor'] = appTheme?.newSettings?.landing?.colors?.LandingSecondaryBackground
            ? `#${appTheme?.newSettings?.landing?.colors?.LandingSecondaryBackground}`
            : '#393939'
        schemeObject['bg'] = appTheme?.newSettings?.landing?.colors?.LandingBackground
            ? `#${appTheme?.newSettings?.landing?.colors?.LandingBackground}`
            : theme.colors.bg[5]
        schemeObject['buttonTextColor'] = appTheme?.newSettings?.landing?.colors?.LandingButtonText
            ? `#${appTheme?.newSettings?.landing?.colors?.LandingButtonText}`
            : '#fff'
        schemeObject['buttonColor'] = appTheme?.newSettings?.landing?.colors?.LandingButtonBackground
            ? `#${appTheme?.newSettings?.landing?.colors?.LandingButtonBackground}`
            : '#565656'
        schemeObject['jobPostText'] = appTheme?.newSettings?.general?.JobPostText
            ? `#${appTheme?.newSettings?.general?.JobPostText}`
            : '#153945'

        if (Array.isArray(fields)) {
            const updatedContent: Content = { ...content }
            fields.forEach((field) => {
                switch (field.alias) {
                    case 'Background color':
                        schemeObject['bg'] = field.value ? `#${field.value}` : schemeObject['bg']
                        break
                    case 'Text color':
                        schemeObject['textColor'] = `#${field.value}`
                        break
                    case 'Button color':
                        schemeObject['buttonColor'] = field.value ? `#${field.value}` : schemeObject['buttonColor']
                        break
                    case 'Heading':
                        updatedContent[field.id] = field.value
                        break
                    case 'Description':
                        updatedContent[field.id] = field.value
                        break
                    case 'Button text':
                        updatedContent[field.id] = field.value
                        break
                    case 'Button URL':
                        if (field.fieldTypeId === 7 && field.value) {
                            setButtonUrl(field.value)
                        }
                        break
                    case 'Job 1':
                        addJob(field.resourceId)
                        break
                    case 'Job 2':
                        addJob(field.resourceId)
                        break
                    case 'Job 3':
                        addJob(field.resourceId)
                        break
                }
            })

            setScheme(schemeObject)
            updateContent(updatedContent)
        }
        setTimeout(() => {
            setLoaded(true)
        }, 1000)
    }, [appTheme])

    const updateContent = (content: Content) => {
        const translations = appTheme?.newSettings?.landing?.structure?.translations || {}
        const newContent = { ...content }

        if (translations) {
            Object.keys(newContent || {}).forEach((contentId) => {
                Object.entries(translations || {}).forEach(([key, value]) => {
                    if (contentId === key) {
                        value.forEach((item) => {
                            item.languageId === languageId ? (newContent[contentId] = item.value) : null
                        })
                    }
                })
            })
        }

        setContent(newContent)
    }
    const getDifference = (expireDate: string) => {
        const date1: Date = new Date(expireDate)
        const date2: Date = new Date()
        // @ts-ignore
        const diffInMs = Math.abs(date2 - date1)

        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24))

        if (diffInDays === 0) {
            return `(${t('152')})`
        } else if (diffInDays === 1) {
            return `(${t('2839')})`
        } else {
            return `(${diffInDays} days)`
        }
    }

    const formatDate = (value: string) => {
        const date = new Date(value)

        const day = date.getDate()
        const month = date.toLocaleString('en-US', { month: 'long' }).slice(0, 3)
        // const year = date.getFullYear()

        return `${day} ${month}`
    }

    const isApplied = (id: number) => {
        if (applications && applications.data && applications.data.length > 0 && id) {
            const post = applications.data.find((item) => item.AdID === id)
            if (post?.Active === 1) {
                return (
                    <Flex
                        py={'6px'}
                        pl={'6px'}
                        pr={'12px'}
                        borderRadius={'24px'}
                        bg={'state.success'}
                        gap={2}
                        alignItems={'center'}
                        cursor={'pointer'}
                    >
                        <Image src={appliedIcon} maxW={'16px'} />
                        <Text fontSize={'xs'} color={'text.white'} fontWeight={'semibold'}>
                            {t('1215')}
                        </Text>
                    </Flex>
                )
            }
            if (post?.Active === 2) {
                return (
                    <Popover trigger={'hover'}>
                        <PopoverTrigger>
                            <Flex
                                py={'6px'}
                                pl={'6px'}
                                pr={'12px'}
                                borderRadius={'24px'}
                                bg={'state.attention'}
                                gap={2}
                                alignItems={'center'}
                                cursor={'pointer'}
                            >
                                <Image src={inProgressIcon} maxW={'16px'} />
                                <Text fontSize={'xs'} color={'text.white'} fontWeight={'semibold'}>
                                    {t('3226')}
                                </Text>
                            </Flex>
                        </PopoverTrigger>
                        <PopoverContent
                            border={'1px solid #E0E0E0'}
                            boxShadow={'0px 16px 40px 0px rgba(0, 0, 0, 0.08)'}
                        >
                            <PopoverArrow />
                            <PopoverBody>
                                <Text fontSize={'xs'} color={'text.main'} fontWeight={'medium'}>
                                    {t('7101')}
                                </Text>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                )
            }

            return
        }
    }

    const months = [
        `${t('489')}`,
        `${t('490')}`,
        `${t('491')}`,
        `${t('492')}`,
        `${t('493')}`,
        `${t('494')}`,
        `${t('495')}`,
        `${t('496')}`,
        `${t('497')}`,
        `${t('498')}`,
        `${t('499')}`,
        `${t('500')}`,
    ]

    // useMemo(() => {
    //     if (headerInfo?.locale?.current) {
    //         const [item] = Object.values(headerInfo?.locale?.current || {})
    //         if (item && item.id) {
    //             setLangId(item.id)
    //         }
    //     }
    // }, [headerInfo])

    useEffect(() => {
        if (token) {
            setJobs([])
        }
    }, [])

    return (
        <Box as={'section'} py={{ base: '80px', xl: '142px' }} bg={scheme.bg}>
            <Container
                maxW={{
                    base: 'container.landingSm',
                    sm: 'container.sm',
                    md: 'container.md',
                    lg: 'container.lg',
                    xl: 'container.xl',
                }}
            >
                <Flex
                    justifyContent={'space-between'}
                    alignItems={{ base: 'start', xl: 'end' }}
                    flexDirection={{ base: 'column', xl: 'row' }}
                    gap={{ base: 6, xl: 0 }}
                >
                    <Box maxW={'600px'} w={'full'}>
                        {Object.values(content || {})[0] ? (
                            <Heading size={'xl'} fontWeight={'semibold'} color={scheme.textColor}>
                                {Object.values(content)[0]}
                            </Heading>
                        ) : (
                            <Skeleton speed={0} w={'265px'} h={'36px'} borderRadius={'4px'} bg={'#D3D3D3'}></Skeleton>
                        )}
                        {Object.values(content)[1] ? (
                            <Text fontSize={'sm'} color={scheme.textColor} mt={6}>
                                {Object.values(content)[1]}
                            </Text>
                        ) : (
                            <SkeletonText
                                mt={6}
                                speed={0}
                                w={{ base: 'full', xl: '488px' }}
                                startColor={'#E6E6E6'}
                                noOfLines={3}
                                spacing={'2'}
                                skeletonHeight={'4'}
                            />
                        )}
                    </Box>

                    <AppButton
                        translate="no"
                        title={Object.values(content)[2] || 'See all jobs'}
                        variant={'secondary'}
                        minW={'160px'}
                        bg={scheme.buttonColor}
                        color={scheme.buttonTextColor}
                        _hover={{ backgroundColor: hexColorEffect(scheme.buttonColor, 30) }}
                        onClick={() => {
                            let finalUrl = buttonUrl
                            const isValidUrl = (url: string): boolean => {
                                return /^https?:\/\//i.test(url)
                            }
                            if (!isValidUrl(buttonUrl)) {
                                if (!buttonUrl.startsWith('/') && buttonUrl.includes('.')) {
                                    finalUrl = `https://${buttonUrl}`
                                } else {
                                    finalUrl = `${window.location.origin}${buttonUrl.startsWith('/') ? buttonUrl : `/${buttonUrl}`}`
                                }
                            }

                            location.href = finalUrl
                        }}
                    />
                </Flex>

                <Box mt={10}>
                    {isDesktop ? (
                        <Box as={'div'} w={'full'} mt={'8'} position={'relative'}>
                            <Box
                                className={'swiper-button image-swiper-button-next'}
                                position={'absolute'}
                                top={'50%'}
                                right={{ lg: '0', xl: '-24px' }}
                                transform={'translateY(-50%)'}
                                zIndex={'10'}
                                display={{ base: 'none', xl: 'block' }}
                            >
                                <Box
                                    w={'12'}
                                    h={'12'}
                                    bg={'bg.1'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    borderRadius={'full'}
                                    boxShadow={'0px -8px 12px rgba(0, 0, 0, 0.08)'}
                                    cursor={'pointer'}
                                    transform={'rotate(180deg)'}
                                >
                                    <IconArrowBackPost w={'20'} h={'14'} fill={'#828282'} />
                                </Box>
                            </Box>
                            <Box
                                className={'swiper-button image-swiper-button-prev'}
                                position={'absolute'}
                                top={'50%'}
                                left={{ lg: '0', xl: '-24px' }}
                                transform={'translateY(-50%)'}
                                zIndex={'10'}
                                display={{ base: 'none', xl: 'block' }}
                            >
                                <Box
                                    w={'12'}
                                    h={'12'}
                                    bg={'bg.1'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    borderRadius={'full'}
                                    boxShadow={'0px 8px 12px rgba(0, 0, 0, 0.08)'}
                                    cursor={'pointer'}
                                >
                                    <IconArrowBackPost w={'20'} h={'14'} fill={'#828282'} />
                                </Box>
                            </Box>
                            <Swiper
                                spaceBetween={16}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={{
                                    nextEl: '.image-swiper-button-next',
                                    prevEl: '.image-swiper-button-prev',
                                    disabledClass: 'swiper-button-disabled',
                                }}
                                modules={[Navigation]}
                                slidesPerView={1.5}
                            >
                                {Object.values(jobs || {}).length > 0
                                    ? Object.values(jobs || {}).map((job) => (
                                          <SwiperSlide>
                                              <Box
                                                  bg={'bg.1'}
                                                  borderRadius={{ base: '0', xl: '8px' }}
                                                  p={6}
                                                  m={2}
                                                  boxShadow={'0px 1px 6px 0px rgba(0,0,0,0.15)'}
                                              >
                                                  <HStack gap={4}>
                                                      <Box
                                                          w={'200px'}
                                                          h={'136px'}
                                                          display={'flex'}
                                                          alignItems={'center'}
                                                          justifyContent={'center'}
                                                      >
                                                          <Image src={job.AdLogo} maxW={'146px'} />
                                                      </Box>

                                                      <VStack
                                                          gap={1}
                                                          alignItems={'start'}
                                                          justifyContent={'start'}
                                                          w={'full'}
                                                      >
                                                          <Flex
                                                              w={'full'}
                                                              gap={4}
                                                              alignItems={'center'}
                                                              justifyContent={'flex-end'}
                                                          >
                                                              <Link
                                                                  href={`/job/${job.AdID}`}
                                                                  fontSize={'xl'}
                                                                  fontWeight={'semibold'}
                                                                  color={scheme.jobPostText}
                                                                  mr={'auto'}
                                                                  maxW={'400px'}
                                                                  isExternal
                                                              >
                                                                  {job.Name}
                                                              </Link>
                                                              {isApplied(job.AdID)}
                                                              {+getDifferenceIn(job.Expire) < 12 &&
                                                                  +getDifferenceIn(job.Expire) !== 0 &&
                                                                  +getDifferenceIn(job.Expire) !== 1 && (
                                                                      <Flex
                                                                          py={'6px'}
                                                                          pl={'6px'}
                                                                          pr={'12px'}
                                                                          borderRadius={'24px'}
                                                                          bg={'state.error1'}
                                                                          gap={2}
                                                                          alignItems={'center'}
                                                                      >
                                                                          <Image src={timerIcon} maxW={'16px'} />
                                                                          <Text
                                                                              fontSize={'xs'}
                                                                              color={'text.white'}
                                                                              fontWeight={'semibold'}
                                                                          >
                                                                              {getDifferenceIn(job.Expire)} {t('1039')}
                                                                          </Text>
                                                                      </Flex>
                                                                  )}
                                                              {+getDifferenceIn(job.Expire) === 1 && (
                                                                  <Flex
                                                                      py={'6px'}
                                                                      pl={'6px'}
                                                                      pr={'12px'}
                                                                      borderRadius={'24px'}
                                                                      bg={'state.error1'}
                                                                      gap={2}
                                                                      alignItems={'center'}
                                                                  >
                                                                      <Image src={timerIcon} maxW={'16px'} />
                                                                      <Text
                                                                          fontSize={'xs'}
                                                                          color={'text.white'}
                                                                          fontWeight={'semibold'}
                                                                      >
                                                                          {t('153')}
                                                                      </Text>
                                                                  </Flex>
                                                              )}
                                                              {+getDifferenceIn(job.Expire) === 0 ? (
                                                                  <Flex
                                                                      py={'6px'}
                                                                      pl={'6px'}
                                                                      pr={'12px'}
                                                                      borderRadius={'24px'}
                                                                      bg={'state.error1'}
                                                                      gap={2}
                                                                      alignItems={'center'}
                                                                  >
                                                                      <Image src={timerIcon} maxW={'16px'} />
                                                                      <Text
                                                                          fontSize={'xs'}
                                                                          color={'text.white'}
                                                                          fontWeight={'semibold'}
                                                                      >
                                                                          {t('152')}
                                                                      </Text>
                                                                  </Flex>
                                                              ) : null}
                                                          </Flex>

                                                          <Text fontSize={'sm'} color={'text.thirdly'}>
                                                              {job.EmpName} · {job.Positions} Positions
                                                          </Text>
                                                          <Text
                                                              fontSize={'sm'}
                                                              fontWeight={'medium'}
                                                              color={'text.secondary'}
                                                          >
                                                              {job.JobTitle}
                                                          </Text>
                                                          <Flex justifyContent={'space-between'} w={'full'} mt={'12px'}>
                                                              <HStack>
                                                                  {job.Place && (
                                                                      <Flex gap={1} mr={3}>
                                                                          <Image src={locationIcon} />
                                                                          <Text
                                                                              fontSize={'sm'}
                                                                              color={'text.secondary'}
                                                                          >
                                                                              {job.Place}
                                                                          </Text>
                                                                      </Flex>
                                                                  )}

                                                                  {job.Extent && (
                                                                      <Flex gap={1}>
                                                                          <Image src={typeIcon} />
                                                                          <Text
                                                                              fontSize={'sm'}
                                                                              color={'text.secondary'}
                                                                          >
                                                                              {job.Extent}
                                                                          </Text>
                                                                      </Flex>
                                                                  )}
                                                              </HStack>
                                                              {job.Expire && (
                                                                  <Flex gap={1} ml={'auto'}>
                                                                      <Image src={timeIcon} />
                                                                      <Text fontSize={'sm'} color={'text.thirdly'}>
                                                                          Expire:
                                                                          <Text as={'span'} color={'text.main'} ml={1}>
                                                                              {formatDate(job.Expire)}{' '}
                                                                              {getDifference(job.Expire)}
                                                                          </Text>
                                                                      </Text>
                                                                  </Flex>
                                                              )}
                                                          </Flex>
                                                      </VStack>
                                                  </HStack>
                                              </Box>
                                          </SwiperSlide>
                                      ))
                                    : Array.from(Array(3).keys()).map((_, index) => (
                                          <SwiperSlide key={index}>
                                              <Box
                                                  bg={'bg.1'}
                                                  borderRadius={'8px'}
                                                  p={6}
                                                  m={2}
                                                  boxShadow={'0px 1px 6px 0px rgba(0,0,0,0.15)'}
                                              >
                                                  <HStack gap={4}>
                                                      <Box w={'200px'} h={'136px'} bg={'#000'} />
                                                      <VStack
                                                          gap={1}
                                                          alignItems={'start'}
                                                          justifyContent={'start'}
                                                          w={'full'}
                                                      >
                                                          <Skeleton
                                                              w={'281px'}
                                                              h={'28px'}
                                                              mb={2}
                                                              speed={0}
                                                              bg={'#D3D3D3'}
                                                          />
                                                          <Skeleton w={'200px'} h={'16px'} speed={0} bg={'#E6E6E6'} />
                                                          <SkeletonText
                                                              speed={0}
                                                              w={'400px'}
                                                              startColor={'#E6E6E6'}
                                                              noOfLines={2}
                                                              spacing={1}
                                                              skeletonHeight={4}
                                                          />
                                                          <Flex justifyContent={'space-between'} w={'full'} mt={'12px'}>
                                                              <HStack>
                                                                  <Flex gap={1} mr={3}>
                                                                      <Image src={locationIcon} />
                                                                      <Skeleton
                                                                          w={'72px'}
                                                                          h={'16px'}
                                                                          speed={0}
                                                                          bg={'#E6E6E6'}
                                                                      />
                                                                  </Flex>

                                                                  <Flex gap={1}>
                                                                      <Image src={typeIcon} />
                                                                      <Skeleton
                                                                          w={'119px'}
                                                                          h={'16px'}
                                                                          speed={0}
                                                                          bg={'#E6E6E6'}
                                                                      />
                                                                  </Flex>
                                                              </HStack>
                                                              <Flex gap={1} ml={'auto'}>
                                                                  <Image src={timeIcon} />
                                                                  <Skeleton
                                                                      w={'144px'}
                                                                      h={'16px'}
                                                                      speed={0}
                                                                      bg={'#E6E6E6'}
                                                                  />
                                                              </Flex>
                                                          </Flex>
                                                      </VStack>
                                                  </HStack>
                                              </Box>
                                          </SwiperSlide>
                                      ))}
                            </Swiper>
                        </Box>
                    ) : (
                        <Flex flexDirection={'column'} gap={4}>
                            {Object.values(jobs).map((job) => (
                                <Box bg={'bg.1'} borderRadius={{ base: 'none', xl: '8px' }} overflow={'hidden'}>
                                    <Flex flexDirection={'column'} gap={4}>
                                        <Box
                                            w={'full'}
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            border={{
                                                base: 'none',
                                                xl: '1px solid',
                                            }}
                                            borderColor={'#DDDFE4'}
                                        >
                                            <Image
                                                src={job.AdLogo}
                                                w={'full'}
                                                maxW={{
                                                    base: '100%',
                                                    xl: '146px',
                                                }}
                                                h={{
                                                    base: '254px',
                                                    xl: 'auto',
                                                }}
                                                objectFit={'cover'}
                                            />
                                        </Box>
                                        <VStack gap={1} px={4} py={3} alignItems={'start'} justifyContent={'start'}>
                                            <Flex w={'full'} gap={4} alignItems={'center'} justifyContent={'flex-end'}>
                                                <Link
                                                    href={`/job/${job.AdID}`}
                                                    fontSize={'xl'}
                                                    fontWeight={'semibold'}
                                                    color={scheme.jobPostText}
                                                    mr={'auto'}
                                                    isExternal
                                                >
                                                    {job.Name}
                                                </Link>
                                                {isApplied(job.AdID)}
                                                {+getDifferenceIn(job.Expire) < 12 &&
                                                    +getDifferenceIn(job.Expire) !== 0 &&
                                                    +getDifferenceIn(job.Expire) !== 1 && (
                                                        <Flex
                                                            py={'6px'}
                                                            pl={'6px'}
                                                            pr={'12px'}
                                                            borderRadius={'24px'}
                                                            bg={'state.error1'}
                                                            gap={2}
                                                            alignItems={'center'}
                                                        >
                                                            <Image src={timerIcon} maxW={'16px'} />
                                                            <Text
                                                                fontSize={'xs'}
                                                                color={'text.white'}
                                                                fontWeight={'semibold'}
                                                            >
                                                                {getDifferenceIn(job.Expire)} {t('1039')}
                                                            </Text>
                                                        </Flex>
                                                    )}
                                                {+getDifferenceIn(job.Expire) === 1 && (
                                                    <Flex
                                                        py={'6px'}
                                                        pl={'6px'}
                                                        pr={'12px'}
                                                        borderRadius={'24px'}
                                                        bg={'state.error1'}
                                                        gap={2}
                                                        alignItems={'center'}
                                                    >
                                                        <Image src={timerIcon} maxW={'16px'} />
                                                        <Text
                                                            fontSize={'xs'}
                                                            color={'text.white'}
                                                            fontWeight={'semibold'}
                                                        >
                                                            {t('153')}
                                                        </Text>
                                                    </Flex>
                                                )}
                                                {+getDifferenceIn(job.Expire) === 0 ? (
                                                    <Flex
                                                        py={'6px'}
                                                        pl={'6px'}
                                                        pr={'12px'}
                                                        borderRadius={'24px'}
                                                        bg={'state.error1'}
                                                        gap={2}
                                                        alignItems={'center'}
                                                    >
                                                        <Image src={timerIcon} maxW={'16px'} />
                                                        <Text
                                                            fontSize={'xs'}
                                                            color={'text.white'}
                                                            fontWeight={'semibold'}
                                                        >
                                                            {t('152')}
                                                        </Text>
                                                    </Flex>
                                                ) : null}
                                            </Flex>
                                            <Text fontSize={'sm'} color={'text.thirdly'}>
                                                {job.EmpName} · {job.Positions} Positions
                                            </Text>
                                            <Text fontSize={'sm'} fontWeight={'medium'} color={'text.secondary'}>
                                                {job.JobTitle}
                                            </Text>
                                            <Flex
                                                justifyContent={'space-between'}
                                                w={'full'}
                                                flexDirection={'column'}
                                                gap={1}
                                                alignItems={'start'}
                                            >
                                                <Flex flexDirection={'column'} gap={1}>
                                                    {job.Place && (
                                                        <Flex gap={1} mr={3}>
                                                            <Image src={locationIcon} />
                                                            <Text fontSize={'sm'} color={'text.secondary'}>
                                                                {job.Place}
                                                            </Text>
                                                        </Flex>
                                                    )}
                                                    {job.Extent && (
                                                        <Flex gap={1}>
                                                            <Image src={typeIcon} />
                                                            <Text fontSize={'sm'} color={'text.secondary'}>
                                                                Permanent
                                                            </Text>
                                                        </Flex>
                                                    )}
                                                </Flex>
                                                {job.Expire && (
                                                    <Flex gap={1} ml={{ base: '0', xl: 'auto' }} alignItems={'center'}>
                                                        <Image w={3} h={3} src={timeIcon} />
                                                        <Flex>
                                                            <Text fontSize={'sm'} color={'text.thirdly'}>
                                                                {t('6632')}:
                                                            </Text>
                                                            <Text
                                                                fontSize={'sm'}
                                                                color={'text.main'}
                                                                ml={1}
                                                                fontWeight={'semibold'}
                                                            >
                                                                {`${
                                                                    months[+job.Expire.split('-')[1] - 1]
                                                                } ${+job.Expire.split('-')[2]}, ${
                                                                    job.Expire.split('-')[0]
                                                                }`}
                                                            </Text>
                                                        </Flex>
                                                    </Flex>
                                                )}
                                            </Flex>
                                        </VStack>
                                    </Flex>
                                </Box>
                            ))}
                        </Flex>
                    )}
                </Box>
            </Container>
        </Box>
    )
}
