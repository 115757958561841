import { Box, Button, Flex, Image, Show, Text } from '@chakra-ui/react'
import { Dialog } from 'primereact/dialog'
import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetHeaderInfoQuery } from '@core/app/api/profileApi'
import { setResetForm } from '@core/app/slices/profile'
import closeBtn from '@core/assets/images/closeBtn.svg'
import collapseBtn from '@core/assets/images/collapseBtn.svg'
import maximizeBtn from '@core/assets/images/maximizeBtn.svg'
import minimizeBtn from '@core/assets/images/minimizeBtn.svg'
import { useIsApplyDialog } from '@core/utils/contexts/ApplyDialogContext/ApplDialogContext'
import { ModalContext } from '@core/utils/contexts/ModalContext/ModalContext'
import { useAppDispatch } from '@core/utils/hooks/hook'
import { ApplyJobBlock } from '@page/post/components/ApplyJob/ApplyJob'
import { useParams } from 'react-router-dom'

interface Props {
    isActive: boolean
    onHide: () => void
}

export const ApplyDialog: FC<Props> = ({ isActive, onHide }) => {
    const dispatch = useAppDispatch()
    const { data: headerInfo } = useGetHeaderInfoQuery({})
    const { openModal, closeModal } = useContext(ModalContext)
    const { t } = useTranslation()
    const { id: currentJobId } = useParams()
    const id =
        localStorage.getItem('apply_dialog') && JSON.parse(localStorage.getItem('apply_dialog') || '{"id": 0}').id

    const { isApplyDialog, collapseDialog, resized, error } = useIsApplyDialog()
    const isLogged = headerInfo && headerInfo.statusCode === 200 && headerInfo.data?.CandidateID

    const header = () => {
        let applyDialog
        try {
            const item = localStorage.getItem('apply_dialog')
            applyDialog = item ? JSON.parse(item) : null
        } catch (error) {
            console.error('Error parsing apply_dialog from localStorage:', error)
            applyDialog = null
        }

        const jobLink = applyDialog?.opened ? applyDialog.job?.link : null
        const jobTitle = applyDialog?.opened ? applyDialog.job?.title : null

        return (
            <Box>
                <Text color={'text.main'} fontWeight={'semibold'}>
                    {t('1589')}
                </Text>
                {jobTitle && (
                    <Text
                        fontSize={'sm'}
                        color={'text.link'}
                        fontWeight={'normal'}
                        cursor={'pointer'}
                        onClick={() => {
                            if (jobLink) {
                                location.href = jobLink
                            }
                        }}
                    >
                        {jobTitle}
                    </Text>
                )}
            </Box>
        )
    }

    const openExitModal = () => {
        const isDirty = JSON.parse(localStorage.getItem('apply_window_dirty') || 'false')
        if (isDirty) {
            openModal({
                size: 'sm',
                isHeaderVisible: false,
                isFooterVisible: false,
                body: (
                    <Box p={6}>
                        <Text fontSize={'2xl'} fontWeight={'semibold'} color={'text.main'}>
                            {t('7054')}?
                        </Text>
                        <Text fontWeight={'medium'} color={'text.secondary'} mt={4}>
                            {t('7055')}.
                        </Text>
                        <Flex w={'full'} justifyContent={'space-between'} alignItems={'center'} mt={'40px'}>
                            <Button
                                variant={'error'}
                                w={'140px'}
                                fontSize={'sm'}
                                ra-data-job-post-id={id}
                                id={'ra-button-job-post-action-application-form-close-ok'}
                                aria-label="Ok"
                                onClick={() => {
                                    dispatch(setResetForm(true))

                                    setTimeout(() => {
                                        closeModal()
                                        onHide()
                                        localStorage.removeItem('mobile_verification')
                                    }, 0)
                                }}
                            >
                                Ok
                            </Button>
                            <Button variant={'secondary'} w={'140px'} onClick={closeModal} aria-label={`${t('416')}`}>
                                {t('416')}
                            </Button>
                        </Flex>
                    </Box>
                ),
            })
        } else {
            dispatch(setResetForm(true))

            setTimeout(() => {
                closeModal()
                onHide()
                localStorage.removeItem('mobile_verification')
            }, 0)
        }
    }

    return (
        <>
            <Show below={'xl'}>
                <Dialog
                    // @ts-ignore
                    header={header}
                    icons={
                        <Button
                            onClick={() => collapseDialog()}
                            className={'p-dialog-header-icon'}
                            style={{ padding: '0', maxWidth: '32px' }}
                            aria-label={`${t('7839')}`}
                        >
                            <Image src={!resized ? maximizeBtn : collapseBtn} alt={`${t('7839')}`} />
                        </Button>
                    }
                    maximizable={false}
                    maximized={true}
                    position={'bottom-right'}
                    visible={isActive}
                    // style={{ width: '544px', height: 'auto', maxHeight:'calc(100% - 120px)' }}
                    style={{ width: '544px', height: 'auto', maxHeight: 'calc(100%)' }}
                    onHide={() => openExitModal()}
                    closeIcon={<Image src={closeBtn} alt={`${t('181')}`} />}
                    // maximizeIcon={<Image src={maximizeBtn} alt={`${t('7343')}`} />}
                    // minimizeIcon={<Image src={minimizeBtn} alt={`${t('7342')}`} />}
                    contentClassName={'p-dialog-hidden'}
                    className={`p-dialog-content-hidden ${
                        (isLogged && !location.pathname.includes('/job/') && !resized) ||
                        (location.pathname.includes('/job/') && !resized)
                            ? 'p-dialog-content-hidden-sticky'
                            : 'apply-window-dialog-expanded'
                    }`}
                    onShow={() =>
                        location.pathname.includes(
                            localStorage.getItem('apply_dialog') &&
                                JSON.parse(localStorage.getItem('apply_dialog')!).job &&
                                JSON.parse(localStorage.getItem('apply_dialog')!).job.link
                        ) && collapseDialog()
                    }
                    draggable={false}
                    modal
                >
                    <ApplyJobBlock />
                </Dialog>
            </Show>
            <Show above={'xl'}>
                <Dialog
                    header={header}
                    icons={
                        <Button
                            onClick={() => collapseDialog()}
                            className={'p-dialog-header-icon collapse-custom-icon'}
                            style={{ padding: '0', maxWidth: '32px' }}
                            aria-label={`${t('7839')}`}
                        >
                            <Image src={resized ? maximizeBtn : collapseBtn} alt={`${t('7839')}`} />
                        </Button>
                    }
                    // maximizable={resized && isApplyDialog ? false : true}
                    // maximized={true}

                    position={'bottom-right'}
                    visible={isActive}
                    style={{ width: '544px', height: 'auto', maxHeight: 'calc(100% - 120px)' }}
                    onHide={() => openExitModal()}
                    closeIcon={<Image src={closeBtn} alt={`${t('181')}`} />}
                    // maximizeIcon={<Image src={maximizeBtn} alt={`${t('7343')}`} />}
                    // minimizeIcon={<Image src={minimizeBtn} alt={`${t('7342')}`} />}
                    contentClassName={resized ? 'p-dialog-hidden' : ''}
                    className={`${error ? 'p-dialog-error' : ''} ${resized ? 'p-dialog-content-hidden' : 'job-apply-dialog-maximized'}`}
                    draggable={false}
                    modal
                >
                    <ApplyJobBlock />
                </Dialog>
            </Show>
        </>
    )
}
