import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const loggedWorkApi = createApi({
    reducerPath: 'loggedWorkApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.ENV === 'local' ? `${process.env.API_URL}/api` : `${window.location.origin}/api`,
        prepareHeaders: (headers) => {
            const token = document.getElementsByName('csrf-token')[0]?.getAttribute('content')
            if (token) headers.set('X-CSRF-TOKEN', token)

            return headers
        },
        credentials: process.env.ENV === 'local' ? 'include' : 'same-origin',
    }),
    endpoints: (builder) => ({
        getData: builder.query({
            query: (arg) => {
                const { fromDate, toDate, scopes, workJobs, workSubProjects, workTrips, workTripIds, workAttachements, workNotes, workStatus, workArticleIds } = arg
                return {
                    url: '/employee/calendar/v2',
                    method: 'GET',
                    params: {
                        fromDate,
                        toDate,
                        scopes,
                        workJobs,
                        workSubProjects,
                        workTrips,
                        workTripIds,
                        workAttachements,
                        workNotes,
                        workStatus,
                        workArticleIds
                    }
                }
            },
            transformResponse: (response: any) => {
                return response.data
            },
        }),
        downloadData: builder.query({
            query: (arg) => {
                const { fromDate, toDate, scopes, workJobs, workSubProjects, workTrips, workTripIds, workAttachements, workNotes, workStatus, workArticleIds, format, ids } = arg

                return {
                    url: '/employee/report/logged-work',
                    method: 'GET',
                    params: {
                        fromDate,
                        toDate,
                        scopes,
                        workJobs,
                        workSubProjects,
                        workTrips,
                        workTripIds,
                        workAttachements,
                        workNotes,
                        workStatus,
                        workArticleIds,
                        format,
                        ids
                    }
                }
            }
        })

    }),
})

export const { useLazyGetDataQuery, useLazyDownloadDataQuery } = loggedWorkApi
