import { Box, Container, Flex, Skeleton, Text, VStack, useTheme } from '@chakra-ui/react'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import { FC, useEffect, useMemo, useState } from 'react'
import { HeaderInfo } from '@core/app/types/profileApiType'

type Content = {
    [key: number | string]: string
}

export const Quote: FC<{ id: number; headerInfo?: HeaderInfo | undefined; languageId?: number }> = ({
    id,
    headerInfo,
    languageId,
}) => {
    const { appTheme } = useAppTheme()
    const theme = useTheme()
    // const [langId, setLangId] = useState<number>()
    const [loaded, setLoaded] = useState<boolean>(false)
    const [content, setContent] = useState<Content>({})
    const [scheme, setScheme] = useState<Content>({})

    useEffect(() => {
        setContent({})
        const schemeObject: Content = {}
        const landing = appTheme?.newSettings?.landing?.structure?.sections
        const section = landing
            ? Object.values(appTheme?.newSettings.landing.structure.sections).find(
                  (section) => section.name === 'Quote' && section.id === id
              )
            : null

        const fields = section ? section.fields : null
        schemeObject['primaryColor'] = appTheme?.newSettings?.landing?.colors?.LandingPrimaryBackground
            ? `#${appTheme?.newSettings.landing.colors.LandingPrimaryBackground}`
            : '#565656'
        schemeObject['secondaryColor'] = appTheme?.newSettings?.landing?.colors?.LandingSecondaryBackground
            ? `#${appTheme?.newSettings.landing.colors.LandingSecondaryBackground}`
            : '#393939'
        schemeObject['bg'] = appTheme?.newSettings?.landing?.colors?.LandingBackgroundLight
            ? `#${appTheme?.newSettings.landing.colors.LandingBackgroundLight}`
            : theme.colors.bg[5]
        schemeObject['textColor'] = appTheme?.newSettings?.landing?.colors?.LandingBackgroundLightText
            ? `#${appTheme?.newSettings.landing.colors.LandingBackgroundLightText}`
            : '#333333'

        if (Array.isArray(fields)) {
            const updatedContent: Content = { ...content }
            fields.forEach((field) => {
                switch (field.alias) {
                    case 'Background color':
                        schemeObject['bg'] = field.value ? `#${field.value}` : schemeObject['bg']
                        break
                    case 'Text color':
                        schemeObject['textColor'] = field.value ? `#${field.value}` : schemeObject['textColor']
                        break
                    case 'Quote':
                        updatedContent[field.id] = field.value
                        break
                    case 'Author':
                        updatedContent[field.id] = field.value
                        break
                    case 'Position':
                        updatedContent[field.id] = field.value
                        break
                }
            })
            setScheme(schemeObject)
            updateContent(updatedContent)
        }
        setTimeout(() => {
            setLoaded(true)
        }, 1000)
    }, [appTheme])

    const updateContent = (content: Content) => {
        const translations = appTheme?.newSettings?.landing?.structure?.translations || {}
        const newContent = { ...content }

        if (translations) {
            Object.keys(newContent || {}).forEach((contentId) => {
                Object.entries(translations || {}).forEach(([key, value]) => {
                    if (contentId === key) {
                        value.forEach((item) => {
                            item.languageId === languageId ? (newContent[contentId] = item.value) : null
                        })
                    }
                })
            })
        }

        setContent(newContent)
    }

    // useMemo(() => {
    //     if (headerInfo?.locale?.current) {
    //         const [item] = Object.values(headerInfo?.locale?.current || {})
    //         if (item?.id) {
    //             setLangId(item.id)
    //         }
    //     }
    // }, [headerInfo])

    return (
        <Box as={'section'} py={{ base: '80px', xl: '165px' }} bg={scheme.bg}>
            <Container
                maxW={{
                    base: 'container.landingSm',
                    sm: 'container.sm',
                    md: 'container.md',
                    lg: 'container.lg',
                    xl: 'container.xl',
                }}
                display={'flex'}
                justifyContent={'center'}
            >
                <Flex w={'full'} maxW={'800px'} justifyContent={'center'} flexDirection={'column'}>
                    <Box maxW={'72px'} mx={'auto'} mb={10}>
                        <svg
                            width={'72'}
                            height={'54'}
                            viewBox={'0 0 72 54'}
                            fill={'none'}
                            xmlns={'http://www.w3.org/2000/svg'}
                        >
                            <path
                                fillRule={'evenodd'}
                                clipRule={'evenodd'}
                                d={
                                    'M32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C18.1052 32 20.1154 31.5934 21.9564 30.8545C21.8002 31.5901 21.2376 32.1906 20.4851 32.3787L18.6468 32.8383C18.2269 32.9433 17.8525 33.1816 17.5796 33.5174L6.342 47.3483C5.61469 48.2435 5.79322 49.5666 6.73175 50.237L10.6213 53.0152C11.4169 53.5835 12.5067 53.4933 13.198 52.802L20.2843 45.7157C27.7857 38.2143 32 28.0401 32 17.4315V16Z'
                                }
                                fill={scheme.textColor}
                            />
                            <path
                                fillRule={'evenodd'}
                                clipRule={'evenodd'}
                                d={
                                    'M72 16C72 7.16344 64.8366 0 56 0C47.1634 0 40 7.16344 40 16C40 24.8366 47.1634 32 56 32C58.1052 32 60.1154 31.5934 61.9564 30.8545C61.8002 31.5901 61.2376 32.1906 60.4851 32.3787L58.6468 32.8383C58.2269 32.9433 57.8525 33.1816 57.5796 33.5174L46.342 47.3483C45.6147 48.2435 45.7932 49.5666 46.7317 50.237L50.6213 53.0152C51.4169 53.5835 52.5067 53.4933 53.198 52.802L60.2843 45.7157C67.7857 38.2143 72 28.0401 72 17.4315V16Z'
                                }
                                fill={scheme.textColor}
                            />
                        </svg>
                    </Box>
                    {Object.values(content || {})[0] ? (
                        <Text fontSize={'40px'} textAlign={'center'} color={scheme.textColor} mb={10}>
                            {Object.values(content)[0]}
                        </Text>
                    ) : (
                        <VStack alignItems={'center'}>
                            <Skeleton
                                speed={0}
                                w={{ base: 'full', xl: '800px' }}
                                h={'36px'}
                                borderRadius={'4px'}
                                bg={'#D3D3D3'}
                            ></Skeleton>
                            <Skeleton
                                speed={0}
                                w={{ base: 'full', xl: '370px' }}
                                h={'36px'}
                                borderRadius={'4px'}
                                bg={'#D3D3D3'}
                            ></Skeleton>
                        </VStack>
                    )}
                    {Object.values(content || {})[1] ? (
                        <Text fontSize={'xl'} color={scheme.textColor} textAlign={'center'} fontWeight={'bold'} mt={2}>
                            {Object.values(content)[1]}
                        </Text>
                    ) : (
                        <Skeleton
                            speed={0}
                            w={'222px'}
                            h={'16px'}
                            mx={'auto'}
                            mt={'40px'}
                            borderRadius={'4px'}
                            bg={'#E6E6E6'}
                        ></Skeleton>
                    )}
                    {Object.values(content || {})[2] ? (
                        <Text fontSize={'xl'} color={scheme.textColor} textAlign={'center'} mt={2}>
                            {Object.values(content)[2]}
                        </Text>
                    ) : (
                        <Skeleton
                            speed={0}
                            w={'305px'}
                            h={'16px'}
                            mx={'auto'}
                            mt={2}
                            borderRadius={'4px'}
                            bg={'#E6E6E6'}
                        ></Skeleton>
                    )}
                </Flex>
            </Container>
        </Box>
    )
}
